<template>
  <div class="container-assistido">
    <DetalhesTelefoneAssistido
      title="Elige tus cuotas"
      subTitle="Puedes simular varias veces la cuota del crédito"
      :offerAssisted="offerAssisted"
      :phoneDetailsAssisted="phoneDetailsAssisted"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
import { MUTATION_MAIS_INFORMACOES } from '../../../store/mutations.type'
import { TOGGLE_OVERLAY, UPDATE_SELECTED } from '../../../store/actions.type'
import DetalhesTelefoneAssistido from './DetalhesTelefoneAssistido.vue'

export default {
  components: { DetalhesTelefoneAssistido },
  data() {
    return {
      visualizarMaisInformacoes: true,
      phoneDetailsAssisted: {
        price: '',
        deviceModel: '',
      },
    }
  },
  methods: {
    dispatchEvents() {
      let queryData = decodeURIComponent(this.$route.query.data)
      let decryptedObject = this.CryptoJS.AES.decrypt(queryData, 'rr13xXNchqGpN50y0NvvuJRYkRKWy2JITlAQTqPH').toString(
        this.CryptoJS.enc.Utf8
      )
      decryptedObject = JSON.parse(decryptedObject)
      this.phoneDetailsAssisted.price = decryptedObject.price
      this.phoneDetailsAssisted.deviceModel = decryptedObject.deviceModel
    },
  },
  computed: {
    ...mapGetters({
      offerEnabledOptions: 'offerEnabledOptions',
      selected: 'offerSelectedNumber',
      maisInformacoes: 'maisInformacoes',
      offerAssisted: 'offerAssisted',
    }),
  },
  mounted() {
    this.dispatchEvents(),
      this.objectButton?.map((objButton, index) => {
        this.offerAssisted?.map((singleOffer) => {
          if (singleOffer.installment == objButton.value) {
            this.objectButton[index].disabled = false
          }
        })
      })
  },
}
</script>

<style scoped>
.btn-parcel {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none;
  text-align: center;
  color: #86888c;
}

.card-phone-details-wrapper {
  margin-top: 40px;
}

.b-card-container {
  padding: 10px;
}

.button {
  margin: 0 1px;
  width: 50px;
  height: 40px;
  color: var(--movistar-grey);
}

.selected-button {
  background-color: #019df4;
  color: #ffffff;
  font-weight: bold;
}

.btnVerificado {
  background: #019df4;
  color: #fff;
  font-weight: bold;
}

.offer-subtitle {
  color: #019df4;
}

.choose-quotas {
  font-weight: bold;
  margin-left: 10px;
}

.continue-button-text {
  font-weight: bold;
  text-align: left;
  color: var(--movistar-grey);
}

.grey {
  color: var(--movistar-grey);
}

.custom-shadow {
  box-shadow: 0 3px 6px #00000029;
}

.dropdown-items {
  font-size: 14px;
}

.total-cuota-margin {
  padding-bottom: 1em;
}

.disabledButton {
  background-color: #b6b9bf;
  color: #ffffff;
}

.simulador-calculadora {
  height: 70px;
  flex-direction: row;
  border-bottom: 1px solid #d3d4d3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button {
  margin: 0 1px;
  width: 50px;
  height: 40px;
  color: var(--movistar-grey);
}

.custom-shadow {
  box-shadow: 0 3px 6px #00000029;
}

.selected-button {
  background-color: #019df4;
  color: #ffffff;
  font-weight: bold;
}

.container-total-cueta {
  margin-bottom: 1rem;
}

.content-total-cueta {
  font-size: 1rem;
  color: #86888c;
  font-weight: bold;
  margin-top: 8px;
}

.legend-con-iva {
  font-size: 14px;
  margin-bottom: 0px;
  position: relative;
  top: 5px;
  margin-left: 5px;
}

.value-total-cueta {
  display: flex;
  font-size: 18px;
  font-weight: bold;
}

.footer-legenda {
  color: #86888c;
  font-size: 13px;
  margin-top: 14px;
}

.legend-value {
  font-size: 14px;
  color: #86888c;
}

.segunda-mais-informacao {
  margin-top: 1.5rem;
}

.more-information {
  display: flex;
  flex-direction: column;
}

.container-more-information {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 17px;
}

.legenda-mais-informacao {
  font-size: 15px;
  color: #019df4;
}

.conteudo-cifrao-assistido {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.box-cifrao-assistido {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.5rem;
  border-bottom: 1px solid #d3d4d3;
}

.titulo-cifrao-assistido {
  font-size: 16px;
  color: #0b2739;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 0px;
}

.header-mais-informacoes {
  background-color: #019df4;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
}

.valor-mais-informacoes {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.container-assistido {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.box-informacoes-assistido {
  position: relative;
  width: 340px;
  height: 170px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 37.4px 12.7px 23.6px 12.5px;
  background: #ffffff;
}

.box-mais-informacoes-assistido {
  width: 340px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 0px 20px 0px;
}

.titulo-mais-informacoes {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0px;
}

.header-svg-assistido {
  position: absolute;
  top: -10px;
  left: 22px;
}

.header-box-information {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d3d4d3;
  margin-bottom: 1rem;
}

p {
  font-size: 15px;
}
.valor-monetario {
  font-weight: bold;
}

.phone-svg-assistido {
  color: #ffffff;
  margin-top: 3px;
}

.title-in-headear-asssistido {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: -45px;
}

.title-header-assistido {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 19px;
}

.footer-informacoes-assistido {
  width: 308px;
  height: 40px;
  border-radius: 40px;
  background-color: #5cb630;
  border: none;
  color: #ffffff;
  font-weight: bold;
}
</style>

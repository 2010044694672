<template>
  <b-row align-v="end">
    <b-col class="no-padding">
      <p class="font-12 no-margin">
        Válido por <span class="blue-text">{{ countdown }}</span> segundos
      </p>
    </b-col>
    <b-col class="no-padding">
      <b-button
        variant="link"
        class="font-12 resend-code no-padding no-margin"
        @click="newPin"
        :disabled="disableNewPin"
      >
        <span>Enviar un código nuevo</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import store from '../store'
import { NEW_PIN_SELF_MANAGE, RESET_AUTH_ERROR } from '../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'OTPNewPinRow',
  props: {
    value: Number,
    countdown: Number,
    disableNewPin: Boolean,
  },
  methods: {
    newPin() {
      const data = {
        document: this.onboardLoginForm.identityCardNumber,
        mobile: this.onboardLoginForm.mobileLastDigits,
      }
      const jsonData = JSON.stringify(data)
      const encrypted = this.CryptoJS.AES.encrypt(jsonData, 'rr13xXNchqGpN50y0NvvuJRYkRKWy2JITlAQTqPH').toString()
      store.dispatch(NEW_PIN_SELF_MANAGE, { backupRoute: 'Login', hash: encrypted })
      this.$emit('countdown', 80)
      store.dispatch(RESET_AUTH_ERROR)
    },
  },
  computed: {
    ...mapGetters({
      onboardLoginForm: 'onboardLoginForm',
    }),
  },
}
</script>

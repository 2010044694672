<template>
  <b-container fluid>
    <MovistarMoneyLogo />

    <b-row class="text-center" align-h="center">
      <b-col cols="12" md="6" class="no-padding">
        <b-row>
          <b-col>
            <p class="title-mis-pagos mb-3">Bienvenido a <span class="title-bold">Mis Pagos</span></p>
            <p class="subtitle-mis-pagos mb-4 mx-4">
              Aquí podrás hacer el pago de tus créditos. <br />
              Antes de empezar ingresa tu documento de identidad
            </p>
          </b-col>
        </b-row>
        <div class="container-form">
          <b-row>
            <b-col>
              <b-form @submit.prevent="submit">
                <input type="hidden" id="user_prefs" name="jsc" />
                <input type="hidden" id="user_prefs2" name="hdim" />
                <div class="mb-3">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-img src="../../assets/icons/id-card.svg" alt="Document icon" />
                    </b-input-group-prepend>
                    <input
                      type="tel"
                      class="v-mask input-style"
                      placeholder="Documento de identidad"
                      v-mask="['### ## ## ## ##']"
                      v-model="cedulaNumber"
                    />
                  </b-input-group>
                </div>

                <div class="container-recaptcha">
                  <vue-recaptcha
                    v-if="loadRecaptcha"
                    :loadRecaptchaScript="true"
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    size="small"
                    :sitekey="sitekey"
                  >
                  </vue-recaptcha>
                  &nbsp;&nbsp;
                </div>
                <div v-if="this.validRecaptcha">
                  <button :disabled="loading" class="btn-acesso-rapido">Continuar</button>
                </div>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <ModalAcessoRapidoError id="modal-acesso-rapido" :error="error" />
  </b-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import apiCaptcha from '../../plugins/apiCaptcha'
import MovistarMoneyLogo from '../../components/MovistarMoneyLogo'
import store from '../../store'
import ModalAcessoRapidoError from '../../components/modal/ModalAcessoRapidoError.vue'
import { FAST_PAYMENT_LOGIN } from '../../store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'AcessoRapidoForm',
  components: { VueRecaptcha, MovistarMoneyLogo, ModalAcessoRapidoError },
  data() {
    return {
      origin: 'Finter',
      step: 1,
      openBox: false,
      valoresSimulador: {},
      cedulaNumber: '',
      error: '',
      validationPhone: process.env.VUE_APP_VALIDATION_PHONE,
      validRecaptcha: false,
      loadRecaptcha: false,
      loading: false,
      sitekey: '6LcfAUEgAAAAAJEpIAj5uCZJ9tMJqq-bmgMvQZHn',
    }
  },
  mounted() {
    if (this.errorFastPayment) {
      this.modalAcessoRapido(this.errorFastPayment)
    }

    if (process.env.VUE_APP_G_RECAPTCHA === 'true') {
      this.loadRecaptcha = true
      this.validRecaptcha = false
    } else {
      this.loadRecaptcha = false
      this.validRecaptcha = true
    }
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      apiCaptcha
        .validacaoCaptcha(recaptchaToken)
        .then((response) => {
          this.validRecaptcha = true
        })
        .catch((error) => this.captchaFailed(error))
    },
    onCaptchaExpired: function () {
      this.validRecaptcha = false
    },
    submit() {
      if (!this.validRecaptcha) {
        this.modalAcessoRapido('Lo sentimos, el captcha debe ser validado.')
        return
      }

      if (this.cedulaNumber && this.validRecaptcha) {
        store.dispatch(FAST_PAYMENT_LOGIN, this.cedulaNumber.replace(/ /g, ''))
      } else {
        this.modalAcessoRapido('Lo sentimos, este número de cédula no puede estar vacío')
      }
    },

    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    modalAcessoRapido(error) {
      this.error = error
      this.showModal('modal-acesso-rapido')
    },
  },
  watch: {
    errorFastPayment: {
      handler() {
        if (this.errorFastPayment) this.modalAcessoRapido(this.errorFastPayment)
      },
    },
  },
  computed: {
    ...mapGetters({
      errorFastPayment: 'errorFastPayment',
    }),
  },
}
</script>

<style scoped>
.container-fluid {
  height: 100vh;
  background-image: url(~@/assets/images/bg-mispagos.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow: auto;
}
.input-style {
  width: 100px;
  background: none;
}
.input-group {
  margin: 1rem auto;
  width: 18.75rem;
}

.btn-acesso-rapido {
  margin: 1rem auto;
  width: 240px;
  height: 46px;
  padding: 14px 83px 14px 87px;
  border-radius: 24px;
  background-color: #ec624b;
  border: none;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.title-mis-pagos {
  color: #019df4;
  font-size: 22px;
}

.subtitle-mis-pagos {
  color: #0b2739;
  font-size: 18px;
}

.title-bold {
  font-weight: bold;
}

@media (max-width: 700px) {
  .container-fluid {
    height: 100vh;
    background-image: url(~@/assets/images/bg-mispagos-mobile.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow: auto;
  }
}
.container-recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
</style>

<template>
  <AssistidoLayout>
    <template #guia>
      <HomeAssistidoGuia />
    </template>
    <HomeFormularioAssistido />
  </AssistidoLayout>
</template>

<script>
import AssistidoLayout from '@/components/assistido/AssistidoLayout.vue'
import HomeAssistidoGuia from '@/components/assistido/HomeAssistido/HomeAssistidoGuia.vue'
import HomeFormularioAssistido from '@/components/assistido/HomeAssistido/IntroducaoAssistido.vue'
import { ACTION_FETCH_OFFER_ASSISTED, FETCH_MY_IP } from '@/store/actions.type'
import { PHONE_DETAILS_ULR } from '@/store/mutations.type'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: { AssistidoLayout, HomeAssistidoGuia, HomeFormularioAssistido },
  created() {
    localStorage.clear()
    sessionStorage.clear()
  },
  mounted() {
    if (this.$route.query.data) {
      let queryData = decodeURIComponent(this.$route.query.data)
      let decryptedObject = this.CryptoJS.AES.decrypt(queryData, 'rr13xXNchqGpN50y0NvvuJRYkRKWy2JITlAQTqPH').toString(
        this.CryptoJS.enc.Utf8
      )
      decryptedObject = JSON.parse(decryptedObject)
      store.commit(PHONE_DETAILS_ULR, decryptedObject)
      store.dispatch(ACTION_FETCH_OFFER_ASSISTED, decryptedObject.id)
      store.dispatch(FETCH_MY_IP)
    }
  },
  watch: {
    ip: {
      handler() {
        console.log('IP ORIGEN: ' + this.ip)
      },
    },
  },
  computed: {
    ...mapGetters({
      ip: 'ip',
    }),
  },
}
</script>

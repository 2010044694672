<template>
  <div class="container-formulario-assistido">
    <section class="header-titulo-assistido">
      <h1 class="titulo-formulario-assistido">
        Aquí puedes iniciar el crédito directamente dando clic en el Botón “Iniciar crédito”
      </h1>
    </section>
    <div class="container-formulario-assistido">
      <section>
        <ul class="lista-formulario-assistido">
          <li class="conteudo-lista-formulario">
            Valida con el cliente su correo electrónico y confírmale que el crédito se hará como titular de la línea.
          </li>
        </ul>
      </section>
      <b-form @submit.prevent="submit">
        <div class="mb-3">
          <b-input-group>
            <b-input-group-prepend>
              <b-img src="@/assets/icons/phone.svg" alt="Phone icon" />
            </b-input-group-prepend>
            <input
              type="tel"
              class="v-mask form-input-item"
              placeholder="Número de celular"
              v-mask="'### ### ## ##'"
              v-model="form.mobile"
              disabled
            />

            <b-input-group-prepend>
              <b-img src="@/assets/icons/id-card.svg" alt="Document icon" />
            </b-input-group-prepend>
            <input
              type="tel"
              class="v-mask pb-2 form-input-item input-documento"
              placeholder="Documento de identidad"
              v-mask="'## ### ### ###'"
              v-model="form.identityCardNumber"
              disabled
            />
          </b-input-group>
          <div class="error-container">
            <p
              v-if="$v.form.mobile.$error || (formErrorArray && formErrorArray.includes('mobile'))"
              class="form-input-error error-assistant"
            >
              Este no es un número Movistar válido
            </p>
            <p
              v-if="validationPhone === 'true' && form.mobile.length > 0 && form.mobile[0] !== '3'"
              class="form-input-error error-assistant"
            >
              El número debe comenzar con 3
            </p>
            <p
              v-if="
                validationPhone === 'false' &&
                form.mobile.length > 0 &&
                form.mobile[0] !== '2' &&
                form.mobile[0] !== '3'
              "
              class="form-input-error error-assistant"
            >
              El número debe comenzar con 2 o 3
            </p>
          </div>

          <div class="mb-3 input-email">
            <b-input-group>
              <b-input-group-prepend>
                <b-img src="@/assets/icons/mail.svg" alt="Mail icon" />
              </b-input-group-prepend>
              <input
                type="email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                oninvalid="setCustomValidity('El correo es inválido')"
                onchange="try{setCustomValidity('')}catch(e){}"
                onkeypress="try{setCustomValidity('')}catch(e){}"
                class="v-mask pb-2 form-input-item"
                placeholder="Ingresa tu correo eletrónico"
                v-model="form.email"
              />
            </b-input-group>
            <p v-if="$v.form.email.$error" class="form-input-error">El correo es inválido</p>
          </div>
        </div>
        <ModalTermsAndConditions :terms="termsAndConditions" title="Términos y Condiciones Movistar Money" />
        <div class="mt-5">
          <FormCheckbox
            id="termsAndConditions"
            label=" El cliente Acepta los términos y condiciones, la política de cobranzas y política de datos personales, declara que los ha leído en su totalidad y está de acuerdo con su contenido."
            @change="acceptTermsConditions"
            v-b-modal.modal-terms-and-conditions
          />
          <div v-show="form.acceptTermsConditions">
            <PreferenciaContato
              textoInfo="Pregúntale al cliente cuáles son sus preferencias de contacto"
              @updateContactPreferences="atualizarPreferencia"
            />
          </div>

          <FormCheckbox
            id="dataPolicy"
            label="El cliente autoriza recibir información comercial conforme a la política de tratamiento de datos personales."
            @change="acceptDataTreatment"
          />

          <section>
            <ul class="lista-formulario-assistido">
              <li class="conteudo-lista-formulario">
                Comunícale al cliente que se realizarán validaciones para determinar su perfil de riesgo crediticio
              </li>
            </ul>
          </section>
        </div>

        <div class="pt-2 pb-3">
          <ButtonContinue text="Iniciar crédito" :disable="desabilitarBotao()" />
        </div>
      </b-form>
    </div>
    <ModalValidarEmailAssistido id="modal-basic" :email="form.email" @validarEmail="receberEmit" />
  </div>
</template>

<script>
import FormCheckbox from '@/components/buttons/FormCheckbox.vue'
import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import { mapGetters } from 'vuex'
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import { emailFormatter } from '@/helpers/common'
import store from '@/store'
import { FETCH_TERMS_CONDITIONS, SEND_FORM, FETCH_URL_CALLBACK, FETCH_MY_IP } from '@/store/actions.type'
import ModalTermsAndConditions from '@/components/modal/ModalTermsAndConditions.vue'
import ModalValidarEmailAssistido from '@/components/modal/ModalValidarEmailAssistido.vue'
import PreferenciaContato from '@/components/PreferenciaContato.vue'
export default {
  components: { FormCheckbox, ButtonContinue, ModalTermsAndConditions, ModalValidarEmailAssistido, PreferenciaContato },
  data: function () {
    return {
      form: {
        id_access: '',
        mobile: '',
        identityCardNumber: '',
        email: '',
        acceptTermsConditions: false,
        acceptDataTreatment: false,
        client: '',
        intendedInstallmentsNumber: null,
        origin: 'Assistido',
        contactPreferences: {
          billingSms: true,
          marketingSms: false,
          billingWhatsapp: true,
          marketingWhatsapp: false,
          billingEmail: true,
          marketingEmail: false,
          billingCall: true,
          marketingCall: false,
        },
      },
      validationPhone: process.env.VUE_APP_VALIDATION_PHONE,
    }
  },
  validations: {
    form: {
      mobile: { required, min: minLength(13) },
      identityCardNumber: { required, min: minLength(14), max: maxLength(19) },
      email: { required, email: (val) => email(emailFormatter(val)) },
      acceptTermsConditions: { required },
    },
  },
  mounted() {
    this.dispatchEvents()
  },
  methods: {
    desabilitarBotao() {
      return !this.form.acceptTermsConditions || !this.form.email || this.validationError
    },
    showModal(modalId) {
      this.$bvModal.show(modalId)
    },
    abrirModal() {
      this.showModal('modal-basic')
    },
    receberEmit() {
      this.validarEmail = true
      this.submit()
    },
    dispatchEvents() {
      store.dispatch(FETCH_TERMS_CONDITIONS, 'Assistido')
      this.form.id_access = this.phoneDetailtUrl.id
      this.form.mobile = this.phoneDetailtUrl.mobile
      this.form.identityCardNumber = this.phoneDetailtUrl.document
      store.dispatch(FETCH_URL_CALLBACK, this.phoneDetailtUrl.urlRetorno)
      store.dispatch(FETCH_MY_IP)
    },
    submit() {
      this.abrirModal()
      if (!this.validarEmail) {
        return
      }
      if (this.$v.form.$error) return
      const newForm = this.newForm()
      store.dispatch(SEND_FORM, newForm)
    },
    newForm() {
      this.form.intendedInstallmentsNumber = this.footerInformationAssisted.installment
      this.form.acceptTermsConditions = true
      this.preencherMarketing()
      let newForm = Object.assign({}, this.form)
      newForm.mobile = newForm.mobile.replace(/ /g, '')
      newForm.identityCardNumber = newForm.identityCardNumber.replace(/ /g, '')
      newForm.client = this.CryptoJS.AES.encrypt(this.ip, 'rr13xXNchqGpN50y0NvvuJRYkRKWy2JITlAQTqPH').toString()
      return newForm
    },
    preencherMarketing() {
      this.form.contactPreferences.marketingCall = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingEmail = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingSms = this.form.acceptDataTreatment
      this.form.contactPreferences.marketingWhatsapp = this.form.acceptDataTreatment
    },
    acceptDataTreatment(newValue) {
      this.form.acceptDataTreatment = newValue
    },
    acceptTermsConditions(newValue) {
      this.form.acceptTermsConditions = newValue
    },
    atualizarPreferencia(value) {
      this.form.contactPreferences = value
    },
  },
  computed: {
    ...mapGetters({
      phoneDetailtUrl: 'phoneDetailtUrl',
      selectOffer: 'selectOffer',
      termsAndConditions: 'termsAndConditions',
      formErrorArray: 'formErrorArray',
      footerInformationAssisted: 'footerInformationAssisted',
      ip: 'ip',
    }),
    validationError() {
      let billingContact = Object.entries(this.form.contactPreferences).filter(([key, value]) => {
        if (key.startsWith('billing') && value) {
          return value
        }
      })
      if (billingContact.length === 0) return true

      return false
    },
  },
}
</script>

<style scoped>
.error-container {
  display: flex;
  flex-direction: column;
}

.error-assistant {
  margin: 0px;
  margin-left: 1rem;
}

.input-email {
  margin: 0 auto;
  margin-top: 3rem;
}

.titulo-formulario-assistido {
  font-size: 24px;
}

.conteudo-lista-formulario {
  text-align: start;
  color: #313235;
  margin-top: 1.5rem;
}
</style>

import { render, staticRenderFns } from "./BoxIntroducaoTelefoneAssistido.vue?vue&type=template&id=9c354f22&scoped=true&"
import script from "./BoxIntroducaoTelefoneAssistido.vue?vue&type=script&lang=js&"
export * from "./BoxIntroducaoTelefoneAssistido.vue?vue&type=script&lang=js&"
import style0 from "./BoxIntroducaoTelefoneAssistido.vue?vue&type=style&index=0&id=9c354f22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c354f22",
  null
  
)

export default component.exports
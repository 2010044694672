<template>
  <div id="app">
    <b-overlay :show="overlay" :opacity="overlayOpacity" variant="white">
      <template #overlay>
        <b-img src="@/assets/icons/spinner.gif" alt="Spinner" />
      </template>
      <Chatbot />
      <router-view />
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chatbot from './components/chatbot/Chatbot.vue'
import { datadogRum } from '@datadog/browser-rum'

export default {
  components: { Chatbot },
  data() {
    return {
      overlayOpacity: '0.50',
    }
  },
  mounted() {
    if (process.env.VUE_APP_DD_ENV === 'prod') {
      datadogRum.init({
        applicationId: '4fabf053-e1ad-4637-9048-70d3de31a219',
        clientToken: 'pub7fa2a072e186d024186faecdd0449cfd',
        site: process.env.VUE_APP_DD_SITE,
        service: process.env.VUE_APP_DD_SERVICE,
        env: process.env.VUE_APP_DD_ENV,
        sessionSampleRate: parseInt(process.env.VUE_APP_DD_SAMPLE_RATE),
        sessionReplaySampleRate: parseInt(process.env.VUE_APP_DD_SESSION_REPLAY_SAMPLE_RATE),
        trackInteractions: true,
        trackUserInteractions: process.env.VUE_APP_DD_TRACK_USER_INTERACTIONS,
        trackResources: process.env.VUE_APP_DD_TRACK_RESOURCES,
        trackLongTasks: process.env.VUE_APP_DD_TRACK_LONG_TASKS,
        trackFrustrations: true,
        defaultPrivacyLevel: process.env.VUE_APP_DD_PRIVACY_LEVEL,
        allowedTracingUrls: [process.env.VUE_APP_DD_ALLOWED_TRACING_ORIGIN],
      })

      datadogRum.startSessionReplayRecording()
    }
  },
  methods: {
    overlayMethod(bool) {
      this.overlay.show = bool
    },
  },
  computed: {
    ...mapGetters({
      overlay: 'overlayStatus',
    }),
  },
}
</script>

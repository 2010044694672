<template>
  <b-card no-body class="sm-card-credits" v-if="this.accountInstallments.globalData">
    <b-card-body class="pb-3">
      <b-card-text class="sm-credits-card-item">
        <p v-if="!isClosureReasonEqualsVentaDeCartera()" class="sm-credits-card-title no-margin text-left">
          Saldo total del Crédito
        </p>
        <div class="sm-credits-card-row">
          <div class="devices-group">
            <div>
              <p
                id="devices"
                v-if="
                  accountInstallmentMambu.productName ||
                  accountInstallmentMambu.access.deviceModel ||
                  accountInstallmentMambu.access.skuName
                "
                class="sm-credits-card-title devices"
              >
                {{ nomeProduto }}
              </p>
              <p
                id="devices"
                v-if="accountInstallmentMambu.access && accountInstallmentMambu.access.accessoryName"
                class="sm-credits-card-title devices"
              >
                {{ accountInstallmentMambu.access.accessoryName }}
              </p>
            </div>
            <img
              v-if="accountInstallmentMambu.origin != 'Libre'"
              @click="showDevices()"
              id="arrow"
              src="@/assets/icons/arrow-down.svg"
              class="arrow-down"
            />
          </div>
          <p v-if="!isClosureReasonEqualsVentaDeCartera()" class="sm-credits-card-item-value">
            {{ this.accountInstallments.globalData.totalCapitalDueValueFmt }}
          </p>
        </div>
        <div class="sm-credits-card-row">
          <p class="sm-credits-card-item">Numero de producto</p>
          <p class="sm-credits-card-item-value">{{ accountInstallmentMambu.contractNumber }}</p>
        </div>
      </b-card-text>
    </b-card-body>

    <b-card-footer class="sm-credits-card-bottom" v-if="this.accountInstallments.globalData">
      <div v-if="isClosureReasonEqualsVentaDeCartera()">
        <div class="closure-messagem">
          <p v-html="formattedMessage"></p>
        </div>
        <div class="sm-credits-card-row pt-2 download-contract-only">
          <button class="button-download-cert contract ml-1" @click="downloadContract(accountInstallmentMambu)">
            <span>Mi contrato</span>
            <div>
              <img class="download-icon-sm" src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
              <span class="download-text-sm download-icon">Descargar</span>
            </div>
          </button>
        </div>
      </div>
      <div v-else>
        <div v-if="this.accountInstallments.globalData.totalCapitalDueValue > 0">
          <div class="sm-credits-card-row mb-3">
            <p class="sm-credits-card-item no-margin">Fecha límite de pago</p>
            <p :class="computedClass">{{ this.accountInstallments.globalData.lastDueDate }}</p>
          </div>
          <div class="sm-credits-card-row">
            <div>
              <p class="sm-credits-card-item-final-value no-margin">
                {{ this.accountInstallments.globalData.lastDueValueFmt }}
              </p>
              <p class="sm-credits-card-item-message no-margin">Valor a pagar</p>
            </div>
            <div class="sm-credits-card-row-button">
              <b-button class="sm-mi-contrato" @click="pagar(accountInstallmentMambu)"> Pagar </b-button>
            </div>
          </div>
          <div class="sm-credits-card-row pt-2">
            <button class="button-download-cert cert-prod" @click="downloadCertificadoProduto(accountInstallmentMambu)">
              <span>Certificado de producto al día</span>
              <img class="download-icon-sm" src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
              <span class="download-text-sm download-icon">Descargar</span>
            </button>
            <button class="button-download-cert contract ml-1" @click="downloadContract(accountInstallmentMambu)">
              <span>Mi contrato</span>
              <div>
                <img class="download-icon-sm" src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
                <span class="download-text-sm download-icon">Descargar</span>
              </div>
            </button>
          </div>
        </div>
        <div v-else>
          <button
            class="button-download-cert cert-acqt ml-1"
            @click="downloadCertificadoPazSalvo(accountInstallmentMambu)"
          >
            <span>Paz y salvo</span>
            <div>
              <img class="download-icon-sm" src="@/assets/icons/seta-baixo.svg" alt="Download icon" />
              <span class="download-text-sm download-icon">Descargar</span>
            </div>
          </button>
        </div>
      </div>
      <ModalDownloadCertificado :errorMessage="errorMessage" />
    </b-card-footer>
  </b-card>
</template>

<script>
import store from '../../store'
import {
  TOGGLE_OVERLAY,
  SELF_MANAGE_GET_APPLICATION_CONTRACT,
  SELF_MANAGE_GET_PRODUCT_CERTIFICATE,
  SELF_MANAGE_GET_ACQUITTANCE_CERTIFICATE,
} from '../../store/actions.type'

import ModalDownloadCertificado from '@/components/modal/ModalDownloadCertificado.vue'

export default {
  name: 'CreditsHeaderCard',
  components: {
    ModalDownloadCertificado,
  },
  data() {
    return {
      errorMessage: '',
    }
  },
  computed: {
    computedClass() {
      let className = 'sm-credits-card-item-value no-margin'
      if (this.accountInstallments.globalData.lastDueDate === 'Inmediato') {
        className = 'sm-credits-card-item-value-red no-margin'
      }
      return className
    },
    nomeProduto() {
      if (this.accountInstallmentMambu.origin === 'Finter') return this.accountInstallmentMambu.access.deviceModel
      if (this.accountInstallmentMambu.origin === 'Assistido') return this.accountInstallmentMambu.access.skuName
      if (this.accountInstallmentMambu.origin === 'Libre') return this.accountInstallmentMambu.productName
      else return null
    },
    formattedMessage() {
      if (!this.accountInstallmentMambu.closureMessage) return ''
      const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
      return this.accountInstallmentMambu.closureMessage.replace(
        emailRegex,
        '<span style="text-decoration: underline;">$1</span>'
      )
    },
  },
  props: {
    accountInstallmentMambu: {},
    accountInstallments: {
      globalData: {
        lastDueDate: '',
      },
    },
  },
  methods: {
    downloadContract(application) {
      store.dispatch(SELF_MANAGE_GET_APPLICATION_CONTRACT, application.keyMambu)
    },
    downloadCertificadoProduto(application) {
      store.dispatch(SELF_MANAGE_GET_PRODUCT_CERTIFICATE, application.keyMambu).catch((error) => {
        this.errorMessage = error
        if (error == 'Ponte al día con tu pagos') {
          this.$bvModal.show('modal-download-certificado')
        } else {
          console.log(error)
        }
      })
    },
    downloadCertificadoPazSalvo(application) {
      store.dispatch(SELF_MANAGE_GET_ACQUITTANCE_CERTIFICATE, application.keyMambu).catch((error) => {
        this.errorMessage = error
        if (error == 'Tu crédito no está habilitado para descargar el certificado') {
          this.$bvModal.show('modal-download-certificado')
        } else {
          console.log(error)
        }
      })
    },
    pagar(application) {
      this.$router.push('/self-manage/payment/' + application.keyMambu)
    },
    showDevices() {
      document.getElementById('arrow').className = this.allDevices ? 'arrow-down' : 'arrow-up'
      document
        .querySelectorAll('[id=devices]')
        .forEach(
          (element) =>
            (element.className = this.allDevices
              ? 'sm-credits-card-title devices'
              : 'sm-credits-card-title devices-open')
        )
      this.allDevices = !this.allDevices
    },
    isClosureReasonEqualsVentaDeCartera() {
      return this.accountInstallmentMambu.closureReason == 'Venta de Cartera Castigada'
    },
  },
}
</script>

<style scoped>
.sm-credits-card-bottom {
  background-color: var(--movistar-grey-7);
  border: none;
}

.sm-credits-card-title {
  font: 700 12px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.devices-group {
  display: flex;
}
.devices {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  margin-bottom: 5px;
}
.devices:last-child {
  margin-bottom: 1rem;
}

.devices-open {
  text-align: start;
  width: 150px;
  overflow: auto;
  word-wrap: break-word;
  height: fit-content;
  margin-bottom: 5px;
}

.sm-credits-card-item {
  font: 12px Telefonica, sans-serif;
  color: var(--movistar-grey);
}

.sm-credits-card-item-value {
  font: 12px Telefonica, sans-serif;
  color: var(--movistar-grey-2);
}

.sm-credits-card-item-value-red {
  font: 12px Telefonica, sans-serif;
  color: red;
}

.sm-credits-card-row {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.sm-credits-card-row-button {
  display: flex;
  align-content: flex-end;
}

.sm-credits-card-item-final-value {
  font: 16px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.sm-credits-card-item-message {
  font: 15px Telefonica, sans-serif;
  color: var(--movistar-pine-green);
  text-align: left;
}

.sm-mi-contrato {
  width: 126px;
  height: 35px;
  padding: 2px 10px 7px 12px;
  border-radius: 32px;
  background-color: var(--movistar-blue);
  border: 0;
  margin: 0 0 0 6px;
  text-align: center;
}

.download-icon {
  color: var(--movistar-blue);
  text-decoration: underline;
}

img.arrow-down {
  margin-top: 0px;
  margin-left: 0px;
  width: 17px;
  height: 14px;
}

img.arrow-up {
  width: 17px;
  margin-left: 0px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-top: 0px;
  height: 14px;
}

.button-download-cert {
  border-radius: 6px;
  border: 2px solid #3699ef;
  background: none;
  cursor: pointer;
  display: flex;
  align-content: center;
  color: #444343;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 4px 4px 3px;
}

.cert-prod {
  width: 220px;
}

.cert-acqt {
  width: 150px;
}

.contract {
  width: 150px;
}

.download-icon-sm {
  padding-left: 10px;
  width: 21px;
  margin-right: 3px;
}

.download-text-sm {
  font-size: 9px;
}

.download-contract-only {
  justify-content: flex-end;
}

.closure-messagem {
  font-style: italic;
  color: rgb(216, 22, 22);
  text-align: left;
  margin: 0px 5px 0px 7px;
}
</style>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { jwt } from '../helpers/jwt.service'
import { API_URL } from './config'
import { errorHandler, selfManageErrorHandler, validationException } from '../helpers/apiHandler'

const Api = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  setHeader(auth, contentType) {
    if (auth) {
      Vue.axios.defaults.headers.common['Authorization'] = `${auth}`
    } else {
      Vue.axios.defaults.headers.common['Authorization'] = `${jwt.getTokenType()} ${jwt.getToken()}`
    }
    if (contentType) {
      Vue.axios.defaults.headers.common['Content-Type'] = `${contentType}`
    }
  },

  setHeaderIP(ip) {
    Vue.axios.defaults.headers.common['http_client_ip'] = `${ip}`
  },

  consultaMobiles(documentNumber) {
    return Vue.axios.get(`publicdata/validate/mobiles/${documentNumber}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getTermsAndConditions(origin) {
    let url = ''
    switch (origin) {
      case 'Libre':
        url = 'publicdata/terms-conditions?origin=Libre'
        break
      case 'Assistido':
        url = 'publicdata/terms-conditions?origin=Assistido'
        break
      default:
        url = 'publicdata/terms-conditions'
        break
    }
    return Vue.axios.get(url).catch((error) => {
      errorHandler(error.response)
    })
  },

  getValidarCampanhaLibre(idCampanha) {
    return Vue.axios.get(`publicdata/validate/campaign/${idCampanha}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getOfferSimulation(payload) {
    Api.setHeader()
    return Vue.axios.get(`publicdata/offer/${payload}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getContract(applicationId) {
    Api.setHeader()
    return Vue.axios.get(`application/contractVisualize/${applicationId}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  executeAction(applicationId, payload) {
    Api.setHeader()
    return Vue.axios.post(`application/${applicationId}/workflow/action`, payload).catch((error) => {
      errorHandler(error.response)
    })
  },

  validarDadosBancarios(applicationId) {
    return this.executeAction(applicationId, { action: 'validadeBankData' })
  },

  getVeridasToken(applicationId) {
    return this.executeAction(applicationId, { action: 'createVeridasId' })
  },

  getVeridasValidarId(applicationId, validateId) {
    const validateIdAction = {
      action: 'validateVeridasId',
      validateId: validateId,
    }
    return this.executeAction(applicationId, validateIdAction)
  },

  getApplication(applicationId) {
    Api.setHeader()
    return Vue.axios.get(`application/${applicationId}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  //## AUTO GESTÃO
  getSelfManageApplications() {
    Api.setHeader()
    return Vue.axios.get('selfmanage/applications').catch((error) => {
      errorHandler(error.response)
    })
  },
  getSelfManageClaims() {
    Api.setHeader()
    return Vue.axios.get('selfmanage/claims').catch((error) => {
      return { data: { done: false, msg: 'No se puede acceder a quejas y reclamos.', claims: [] } }
    })
  },
  postSelfManageUpdateCaseStatus(caseId, status) {
    Api.setHeader()
    return Vue.axios.post(`selfmanage/updateCaseStatus/${caseId}/${status}`).catch((error) => {
      return { data: { done: false, msg: 'No se puede actualizar el estado del reclamo.', claims: [] } }
    })
  },
  getSelfManageApplicationsLiberadas() {
    Api.setHeader()
    return Vue.axios.get('selfmanage/loanInProgress').catch((error) => {
      errorHandler(error.response)
    })
  },
  getSelfManageApplicationPaymentPlan(loanAccountId) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/paymentPlanning/${loanAccountId}`).catch((error) => {
      errorHandler(error.response)
    })
  },
  getSelfManageApplicationMambu(loanAccountId) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/loanInProgress/${loanAccountId}`).catch((error) => {
      errorHandler(error.response)
    })
  },
  async getSelfManageApplicationConctact(loanAccountId, callback, useDefaultLoading = true) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/contract/${loanAccountId}`, {
      responseType: 'arraybuffer',
      onDownloadProgress: callback || null,
    })
  },
  async getSelfManageProductCertificate(loanAccountId, callback, useDefaultLoading = true) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/productCertificate/${loanAccountId}`, {
      responseType: 'arraybuffer',
      onDownloadProgress: callback || null,
    })
  },
  async getSelfManageAcquittanceCertificate(loanAccountId, callback, useDefaultLoading = true) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/acquittanceCertificate/${loanAccountId}`, {
      responseType: 'arraybuffer',
      onDownloadProgress: callback || null,
    })
  },
  async getSelfManageApplicationExtract(loanAccountId, callback, useDefaultLoading = true) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/extract/${loanAccountId}`, {
      responseType: 'arraybuffer',
      onDownloadProgress: callback || null,
    })
  },
  selfManageApplicationSendEmailComprovanteEpayco(body) {
    Api.setHeader()
    return Vue.axios.post('selfmanage/sendProofPaymentEpayco', body).catch((error) => {
      errorHandler(error.response)
    })
  },
  async getSelfManageApplicationApoliceSeguro(loanAccountId, callback, useDefaultLoading = true) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/insurancePolicy/${loanAccountId}`, {
      responseType: 'arraybuffer',
      onDownloadProgress: callback || null,
    })
  },
  getApplicationBalance(loanAccountId) {
    Api.setHeader()
    return Vue.axios.get(`selfmanage/balance/${loanAccountId}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  //## FIM AUTO GESTÃO

  //## EPAYCO
  postEpaycoResponse(epaycoResponse) {
    Api.setHeader()
    return Vue.axios
      .post(`selfmanage/epayco/${epaycoResponse.idCredito}/${epaycoResponse.refEpayco}`)
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoMobileResponse(epaycoResponse) {
    let jsonData = JSON.stringify(epaycoResponse.mobileData)
    Api.setHeader()
    return Vue.axios
      .post(`selfmanage/epayco/${epaycoResponse.idCredito}/mobile`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoCheckoutRequisicao(epaycoRequisicao) {
    let jsonData = JSON.stringify(epaycoRequisicao)
    Api.setHeader()
    return Vue.axios
      .post(`selfmanage/epayco/${epaycoRequisicao.encodeKeyMambu}/request`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoResponseAcessoRapido(epaycoResponse) {
    return Vue.axios
      .post(`publicdata/epayco/${epaycoResponse.idCredito}/${epaycoResponse.refEpayco}`)
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoMobileResponseAcessoRapido(epaycoResponse) {
    let jsonData = JSON.stringify(epaycoResponse.mobileData)
    return Vue.axios
      .post(`publicdata/epayco/${epaycoResponse.idCredito}/mobile`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoCheckoutRequisicaoAcessoRapido(epaycoRequisicao) {
    let jsonData = JSON.stringify(epaycoRequisicao)
    return Vue.axios
      .post(`publicdata/epayco/${epaycoRequisicao.encodeKeyMambu}/request`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoResponseAntecipado(epaycoResponse) {
    return Vue.axios
      .post(`publicdata/advanced-payment/epayco/${epaycoResponse.idCredito}/${epaycoResponse.refEpayco}`)
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoMobileResponseAntecipado(epaycoResponse) {
    let jsonData = JSON.stringify(epaycoResponse.mobileData)
    return Vue.axios
      .post(`publicdata/advanced-payment/epayco/${epaycoResponse.idCredito}/mobile`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoResponseFianca(epaycoResponse) {
    return Vue.axios
      .post(`publicdata/guarantee-payment/epayco/${epaycoResponse.idCredito}/${epaycoResponse.refEpayco}`)
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoMobileResponseFianca(epaycoResponse) {
    let jsonData = JSON.stringify(epaycoResponse.mobileData)
    return Vue.axios
      .post(`publicdata/guarantee-payment/epayco/${epaycoResponse.idCredito}/mobile`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  //## EPAYCO FIM

  //## ASSISTIDO
  setHeaderAssistido() {
    return Vue.axios
      .post('oauth/token', 'grant_type=client_credentials', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization:
            'Bearer NTNv7j0TuYARvmNMmWXo6fKvM4o6nv/aUi9ryX38ZH+L1bkrnD1ObOQ8JAUmHCBq7Iy7otZcyAagBLHVKvvYaIpmMuxmARQ97jUVG16Jkpkp1wXOPsrF9zwew6TpczyHkHgX5EuLg2MeBuiT/qJACs1J0apruOOJCg/gOtkjB4c=',
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  async postWorkflowActionAssisted(applicationId, payload) {
    let response = await Api.setHeaderAssistido()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return Vue.axios
      .post(`application/${applicationId}/workflow/action`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  async postSimuladorAssistido(payload) {
    return Vue.axios.get(`publicdata/offer-operator-assisted/${payload}`).then((response) => {
      return response
    })
  },

  async getOfferAssisted(idAcess) {
    let response = await Api.setHeaderAssistido()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return Vue.axios
      .get(`publicdata/offer-assisted/${idAcess}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  async getContractAssisted(applicationId) {
    let response = await Api.setHeaderAssistido()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return Vue.axios
      .get(`application/contractVisualize/${applicationId}/?channel=Assistido`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  async getApplicationAssisted(applicationId) {
    let response = await Api.setHeaderAssistido()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return Vue.axios
      .get(`application/${applicationId}/?channel=Assistido`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  //## ASSISTIDO FIM

  //FAST PAYMENT
  getFastPaymentValidate(cedulaNumber) {
    return Vue.axios.get(`publicdata/fast-payment/${cedulaNumber}`).catch((error) => {
      errorHandler(error.response)
    })
  },
  getFastPaymentApplicationsMambu(cedulaNumber) {
    return Vue.axios.get(`publicdata/fast-payment/loanInProgress/${cedulaNumber}`).catch((error) => {
      errorHandler(error.response)
    })
  },
  getFastPaymentApplicationPaymentPlan(cedulaNumber, loanAccountId) {
    return Vue.axios.get(`publicdata/fast-payment/paymentPlanning/${cedulaNumber}/${loanAccountId}`).catch((error) => {
      errorHandler(error.response)
    })
  },
  //FAST PAYMENT END

  //ADVANCED PAYMENT
  getPropostaPagamentoAntecipado(idProposta, hash) {
    return Vue.axios
      .get(`publicdata/advanced-payment/epayco/loan/${idProposta}/${hash}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoCheckoutRequisicaoPagamentoAntecipado(epaycoRequisicao) {
    let jsonData = JSON.stringify(epaycoRequisicao)
    return Vue.axios
      .post(`publicdata/advanced-payment/epayco/${epaycoRequisicao.proposalId}/request`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  postEpaycoResponsePagamentoAntecipado(epaycoResponse) {
    return Vue.axios
      .post(`publicdata/advanced-payment/epayco/${epaycoResponse.idCredito}/${epaycoResponse.refEpayco}`)
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  enviarComprovanteEpaycoAntecipado(body) {
    Api.setHeader()
    return Vue.axios.post('publicdata/advanced-payment/epayco/sendProofPaymentEpayco', body)
  },

  postFiancaCheckoutRequisicaoPagamento(fiancaRequisicao) {
    let jsonData = JSON.stringify(fiancaRequisicao)
    return Vue.axios
      .post(`publicdata/guarantee-payment/epayco/${fiancaRequisicao.proposalId}/request`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  getPropostaPagamentoFianca(idProposta, hash) {
    return Vue.axios
      .get(`publicdata/guarantee-payment/epayco/loan/${idProposta}/${hash}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },
  enviarComprovanteEpaycoFianca(body) {
    Api.setHeader()
    return Vue.axios.post('publicdata/guarantee-payment/sendProofPaymentEpayco', body)
  },
  //ADVANCED PAYMENT END

  postApplication(params) {
    return Vue.axios.post('publicdata/application', params).catch((error) => {
      validationException(error.response)
    })
  },

  changeStatus(proposalId, status) {
    Api.setHeader()
    return Vue.axios.post(`application/${proposalId}/change-status/${status}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getAddressDepartments() {
    Api.setHeader()
    return Vue.axios.get(`address/departments`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getAddressCities(departmentCode) {
    Api.setHeader()
    return Vue.axios.get(`address/cities/${departmentCode}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getAddressNeighborhood(cityCode) {
    Api.setHeader()
    return Vue.axios.get(`address/neighborhood/${cityCode}`).catch((error) => {
      errorHandler(error.response)
    })
  },

  postAddressForm(proposalId, body) {
    Api.setHeader()
    return Vue.axios.post(`address/save/${proposalId}`, body).catch((error) => {
      errorHandler(error.response)
    })
  },

  getBanks() {
    Api.setHeader()
    return Vue.axios.get(`publicdata/banks`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getAddressClient() {
    Api.setHeader()
    return Vue.axios.get(`address`).catch((error) => {
      errorHandler(error.response)
    })
  },

  getMyIP() {
    return fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((response) => {
        return response.ip
      })
  },

  getMinhaLocalizacao() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
    return fetch(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAD3Sy6CS9T7uit4f9gfbHrfBoKGPWFXsc`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        return response
      })
  },

  sendError(context, body) {
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json'
    let jsonData = JSON.stringify(body)
    return Vue.axios.post(`application/logFront`, jsonData).catch((error) => {
      errorHandler(error.response)
      Vue.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    })
  },

  //SELF MANAGE ROUTES
  selfManageResetBorrower() {
    Api.setHeader()
    return Vue.axios.get(`borrower/`).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageUpdateContact(body) {
    Api.setHeader()
    return Vue.axios.post(`borrower/contactInsert`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageChangeEmail(body) {
    Api.setHeader()
    return Vue.axios.post(`borrower/changeEmail`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageUpdateContactPreferences(body) {
    Api.setHeader()
    return Vue.axios.put(`borrower/contact-preferences`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },

  //SELF MANAGE ROUTES END

  // EMAIL ANTECIPO
  postReenviarEmailAntecipo(proposalId, email) {
    return Vue.axios.post('publicdata/advanced-payment/resend-email', { proposalId, email })
  },

  getEmailReenvioLinkAntecipo(form) {
    return Vue.axios.get(`publicdata/advanced-payment-email/customer-email`, {
      params: {
        mobile: form.mobile !== '' ? form.mobile : null,
        document: form.document !== '' ? form.document : null,
      },
    })
  },

  // FIM EMAIL ANTECIPO

  // EMAIL FIANCA
  postReenviarEmailFianca(proposalId, email) {
    return Vue.axios.post('publicdata/guarantee-payment/resend-email', { proposalId, email })
  },

  getEmailReenvioLinkFianca(form) {
    return Vue.axios.get(`publicdata/guarantee-payment/customer-email`, {
      params: {
        mobile: form.mobile !== '' ? form.mobile : null,
        document: form.document !== '' ? form.document : null,
      },
    })
  },

  // FIM EMAIL FIANCA

  // EMAIL VERIDAS ASSISTIDO
  async postReenviarEmailVeridas(proposalId, email) {
    const body = {
      action: 'sendEmailVeridas',
      email: email,
      firstEmail: false,
    }
    let response = await Api.setHeaderAssistido()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return await Vue.axios
      .post(`application/${proposalId}/workflow/action`, body)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw new Error(error.response.data.message)
      })
  },

  // FIM EMAIL VERIDAS ASSISTIDO

  // PARAMETROS
  setHeaderParametros() {
    return Vue.axios
      .post('oauth/token', 'grant_type=client_credentials', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${process.env.VUE_APP_TOKEN_CONFIGURACAO}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        errorHandler(error.response)
      })
  },

  async getParametros(canal) {
    let response = await Api.setHeaderParametros()
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
    return await Vue.axios.get('configuration/parameters', { params: { channel: canal } })
  },

  validarEmailVeridas(idProposta, hash) {
    return Vue.axios.get(`publicdata/validate/veridas/assisted/${idProposta}/${hash}`)
  },
  invalidarEmailVeridas(idProposta, hash) {
    return Vue.axios.get(`publicdata/invalidate/veridas/assisted/${idProposta}/${hash}`, {
      headers: {
        Authorization: '',
      },
    })
  },
}

export default Api

<template>
  <b-modal :id="id" header-border-variant="white" footer-border-variant="white" size="sm">
    <template #modal-header>
      <h1 class="text-center title-onboard">¡Recuerda!</h1>
      <b-button variant="blank" @click="fecharModal">
        <IconeFechar />
      </b-button>
    </template>
    <p class="description">Acabamos de enviar un link de pago al correo electrónico del cliente.</p>

    <ol>
      <li>
        Si el link no llega, da clic en "Volver a enviar link" puedes enviar hasta {{ quantidadeReenvioEmail }} veces el
        link.
      </li>
      <li>Puedes ingresar otro correo y enviar el link ahí.</li>
      <li>El cliente debe realizar el pago en máximo {{ validadeEmail }} horas hábiles.</li>
      <li><b>¿El link de pago no ha llegado?</b></li>
    </ol>
    <template #modal-footer>
      <ButtonContinue text="Volver a enviar" inverted class="btn-continue" @click="$emit('continuar')" />
    </template>
  </b-modal>
</template>
<script>
import { BUSCAR_PARAMETROS } from '@/store/actions.type'

import ButtonContinue from '@/components/buttons/ButtonContinue.vue'
import IconeFechar from '@/components/icones/IconeFechar.vue'

export default {
  components: {
    ButtonContinue,
    IconeFechar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validadeEmail: null,
      quantidadeReenvioEmail: null,
    }
  },
  async mounted() {
    this.validadeEmail = 20
    this.quantidadeReenvioEmail = 4
  },
  methods: {
    fecharModal() {
      this.$bvModal.hide(this.id)
      this.$emit('fechar')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-sm {
  max-width: 412px;
  margin-top: 4rem;
  .modal-body {
    max-width: 412px;
  }
  .modal-content {
    max-width: 412px;
  }
}

::v-deep .modal-header {
  padding-bottom: 0px;
  justify-content: center;
  position: relative;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .btn-blank {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

::v-deep .modal-body {
  .description {
    margin: 0rem 2rem;
    text-align: center;
    color: #019df4;
  }

  ol {
    text-align: left;
    padding: 0px;
    margin-top: 2rem;
    list-style-position: inside;
    li {
      padding-left: 0.4rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      &::marker {
        font-weight: bold;
      }
    }
  }
}

::v-deep .modal-footer {
  justify-content: center;

  .btn-continue {
    width: 50%;
  }
}
</style>

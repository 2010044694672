<template>
  <div class="bg-sm">
    <SMNavbar />
    <CreditsHeader />
    <CreditsPayments v-if="!isVentaDeCartera()" />
    <CreditsPaymentInstallments v-if="!isVentaDeCartera()" />
    <hr />
    <Footer />
  </div>
</template>

<script>
import SMNavbar from '../../components/SelfManage/SMNavbar'
import CreditsHeader from '../../components/SelfManage/CreditsHeader'
import Footer from '../../components/SelfManage/Footer'
import CreditsPayments from '../../components/SelfManage/CreditsPayments'
import CreditsPaymentInstallments from '../../components/SelfManage/CreditsPaymentInstallments'
import { mapGetters } from 'vuex'

import store from '../../store'
import { SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN, SELF_MANAGE_GET_APPLICATION_MAMBU } from '../../store/actions.type'
import { Persist } from '../../helpers/persist.service'

export default {
  name: 'MyCredits',
  components: {
    SMNavbar,
    CreditsHeader,
    CreditsPayments,
    CreditsPaymentInstallments,
    Footer,
  },
  computed: {
    ...mapGetters({
      accountInstallmentMambu: 'selfManageApplicationMambu',
    }),
  },
  mounted() {
    window.scrollTo(0, 0)
    Persist.clearInstallmentPayment()
    Persist.clearDecreasePayment()
    store.dispatch(SELF_MANAGE_GET_APPLICATION_PAYMENT_PLAN, this.$route.params.idCredito)
    store.dispatch(SELF_MANAGE_GET_APPLICATION_MAMBU, this.$route.params.idCredito)
  },
  methods: {
    isVentaDeCartera() {
      return (
        !!this.accountInstallmentMambu.closureReason &&
        this.accountInstallmentMambu.closureReason == 'Venta de Cartera Castigada'
      )
    },
  },
}
</script>

<template>
  <div>
    <SMNavBar />
    <b-container>
      <PagamentoFiancaHeader />
      <PagamentoFiancaForm @pagar="configurarCheckout" />
      <PaymentTypesFianca :antecipo="false" />
    </b-container>
    <FooterFianca />
    <b-modal centered id="modal-efetivo" header-border-variant="white" footer-border-variant="white">
      <template #modal-header>
        <b-button variant="blank" @click="fecharModal()">
          <IconeFechar />
        </b-button>
      </template>
      <div class="mx-2">
        <div class="py-1">
          <img class="mx-auto img-center" src="@/assets/images/persons-jumping.png" alt="" width="200" />
          <p class="text-center title-onboard">¡Importante!</p>
          <p class="text-center">Guarda el Pin que se generará en el siguiente paso para poder completar tu pago</p>
        </div>
      </div>
      <template #modal-footer>
        <b-row align-h="center" cols="1" cols-md="2">
          <b-col>
            <ButtonContinueBlue text="Continuar" class="btn-continue" @click="continueModal()" />
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'

import SMNavBar from '@/components/SelfManage/SMNavbar.vue'
import PagamentoFiancaHeader from '@/components/PagamentoFianca/PagamentoFiancaHeader.vue'
import PagamentoFiancaForm from '@/components/PagamentoFianca/PagamentoFiancaForm.vue'
import PaymentTypesFianca from '@/components/SelfManage/PaymentTypesFianca.vue'
import FooterFianca from '@/components/SelfManage/FooterFianca.vue'

import ButtonContinueBlue from '@/components/buttons/ButtonContinueBlue'
import IconeFechar from '@/components/icones/IconeFechar.vue'

import {
  TOGGLE_OVERLAY,
  EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_FIANCA,
  GET_PROPOSAL_ADVANCED_PAYMENT_FIANCA,
} from '@/store/actions.type'

export default {
  components: {
    SMNavBar,
    PagamentoFiancaHeader,
    PagamentoFiancaForm,
    PaymentTypesFianca,
    FooterFianca,
    IconeFechar,
    ButtonContinueBlue,
  },
  data() {
    return {
      epaycoData: {
        proposalId: this.$route.params.idProposta,
        name: 'Pago fianza',
        description: 'Pago fianza',
        invoice: '',
        currency: 'COP',
        amount: '',
        tax_base: '',
        tax: '',
        country: 'CO',
        lang: 'ES',
        test: process.env.VUE_APP_EPAYCO_TEST,
        methodsDisable: [],
        external: false,
        extra1: 'Pago fianza',
        extra2: '',
        extra3: '',
        extra5: '',
        method: 'GET',
        response:
          process.env.VUE_APP_FRONT_URL + '/guarantee-payment-response/' + this.$route.params.idProposta + '/response',
        confirmation:
          process.env.VUE_APP_API_URL +
          '/publicdata/guarantee-payment/epayco/' +
          this.$route.params.idProposta +
          '/confirmation',
        name_billing: '',
        address_billing: '',
        type_doc_billing: 'CC',
        number_doc_billing: '',
        mobilephone_billing: '',
        meioPagamento: '',
      },
    }
  },
  mounted() {
    Vue.loadScript('/js/epayco/checkout.js')
      .then((ePaycoScript) => {
        ePaycoScript.id = 'epaycoScript'
      })
      .catch(() => {
        console.log('Problemas ao carregar script epayco.')
      })
    this.$store.dispatch(GET_PROPOSAL_ADVANCED_PAYMENT_FIANCA, this.$route.params)
  },
  methods: {
    abrirModal() {
      this.$bvModal.show('modal-efetivo')
    },
    fecharModal() {
      this.$bvModal.hide('modal-efetivo')
    },
    continueModal() {
      this.$bvModal.hide('modal-efetivo')
      this.salvarDadosRequisicaoEpayco()
    },
    configurarCheckout(tipoPagamento) {
      if (tipoPagamento === 'Efectivo') {
        this.epaycoData.extra2 = tipoPagamento
        window.handler = window.ePayco.checkout.configure({
          key: '956420d37a9be443e402d1cd2dc77d78',
          test: process.env.VUE_APP_EPAYCO_TEST,
        })
        this.abrirModal()
        return
      }
      if (tipoPagamento === 'Tarjeta-PSE') {
        this.epaycoData.extra2 = tipoPagamento
        window.handler = window.ePayco.checkout.configure({
          key: 'b671fd04ecb162de2728c85faaf8aa25',
          test: process.env.VUE_APP_EPAYCO_TEST,
        })
        this.salvarDadosRequisicaoEpayco()
      }
    },
    salvarDadosRequisicaoEpayco() {
      this.$store.dispatch(TOGGLE_OVERLAY, true)
      this.epaycoData.extra3 = this.advancedPaymentFianca.idSalesOrder
      this.epaycoData.extra5 = this.propostaMambu.contractNumber
      this.epaycoData.invoice = uuid.v4()
      this.epaycoData.amount = this.advancedPaymentFianca.guaranteePayment
      this.epaycoData.method = 'GET'
      this.epaycoData.name_billing = this.advancedPaymentFianca.borrower.name
      this.epaycoData.type_doc_billing = 'CC'
      this.epaycoData.number_doc_billing = this.advancedPaymentFianca.borrower.cpf
      this.epaycoData.mobilephone_billing = this.advancedPaymentFianca.mobile
      this.$store.dispatch(EPAYCO_SALVAR_CHECKOUT_REQUISICAO_PAGAMENTO_FIANCA, this.epaycoData).then((response) => {
        if (response.status == 200) {
          window.ePayco.checkout.open(this.epaycoData)
          this.$store.dispatch(TOGGLE_OVERLAY, false)
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      advancedPaymentFianca: 'advancedPaymentFianca',
    }),
  },
}
</script>

<style scoped lang="scss">
.img-center {
  display: flex;
  align-items: center;
}
.container {
  max-width: 30rem;
}
::v-deep .modal-header {
  justify-content: flex-end !important;
  padding-bottom: 0px;
}

::v-deep .modal-footer {
  justify-content: space-around !important;
  .row {
    width: 100%;
    .col {
      display: flex;
      justify-content: center;
      .btn-continue {
        width: 90%;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import api from '../plugins/api'
import { API_URL } from '../plugins/config'
import { queryString } from '../helpers/common'
import { errorHandler, selfManageErrorHandler } from '../helpers/apiHandler'

const ApiAuth = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  postOauthToken(params) {
    let salvarPassword = params.password
    params.password = Vue.CryptoJS.AES.encrypt(params.password, 'rr13xXNchqGpN50y0NvvuJRYkRKWy2JITlAQTqPH').toString()
    const qs = queryString(params)
    params.password = salvarPassword

    const auth = `${'Basic '}${process.env.VUE_APP_SECRET}`
    const contentType = 'application/x-www-form-urlencoded'
    api.setHeader(auth, contentType)

    return Vue.axios.post('oauth/token', qs).catch((error) => {
      errorHandler(error.response)
    })
  },

  newPin(proposalId) {
    return Vue.axios.post(`publicdata/${proposalId}/new-pin`).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },

  newPinSelfManage(hash) {
    return Vue.axios.post(`publicdata/new-pin-ag/?data=${encodeURIComponent(hash)}`).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },

  selfManageLogin(body) {
    api.setHeader()
    return Vue.axios.post(`publicdata/onboardingAccess`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageUpdatePassword(body) {
    api.setHeader()
    return Vue.axios.post(`user/updatePassword`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageResetPassword(body) {
    api.setHeader()
    return Vue.axios.post(`publicdata/forgottenPassword`, body).catch((error) => {
      selfManageErrorHandler(error.response)
    })
  },
  selfManageResetForgottenPassword(body, hashes) {
    api.setHeader()
    return Vue.axios
      .post(`publicdata/resetForgottenPassword/${hashes.idClient}/${hashes.idProposal}/${hashes.idHash}`, body)
      .catch((error) => {
        selfManageErrorHandler(error.response)
      })
  },
  selfManageValidateLinkForgottenPassword(hashes) {
    api.setHeader()
    return Vue.axios
      .get(`publicdata/validateLinkResetForgottenPassword/${hashes.idClient}/${hashes.idHash}`)
      .catch((error) => {
        selfManageErrorHandler(error.response)
      })
  },
}

export default ApiAuth
